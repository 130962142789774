/* Load Fonts */
@font-face{
  font-family: 'Inter';
  src: url('./assets/fonts/GT-America-Standard-Light-Trial.otf') format('opentype');
  font-weight: 400;
}
@font-face{
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 500;
}
@font-face{
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face{
  font-family: 'Inter';
  src: url('./assets/fonts/GT-America-Extended-Black-Trial.otf') format('opentype');
  font-weight: 600;
}
@font-face{
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}


:root{
  --dark: #222222;
  --pink: #C8006A;
  --white: #f4f4f4;
  --grey: #969696;
  --dark-grey: #2E2E2E;
  --light-grey: #c1c1c1;
}
::selection {

}

/* Universal Elements */
*{
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  overflow-x:hidden;
  background-color: var(--dark);
}

a{
  text-decoration: none;
}
a:hover{
  cursor:pointer;
}
h1, h2, h3, h4, p > span{
  font-family: 'Inter';
  font-weight: 500;
  color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-size: 4.5rem;
  line-height: 97.5%;

}
h3{
  font-size: 1.5rem;
}
h4, p > span{
  font-size: 1.25rem;
}
h4{
  font-weight: 700;
  font-size: 0.85rem;
  text-transform: uppercase;
}
h5{
  font-size: 1.25rem;
  font-family: 'Inter';
  font-weight: 600;
  color: white;
}
h6{
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 700;
  color: var(--grey);
}
p{
  font-family: 'Inter';
  font-weight: 300;
  font-size: 1.25rem;
  color: var(--white);
  line-height: 125%;
  text-transform:none;
  margin-bottom: 1rem;
}

p.label, span.label{
  font-size: 1rem;
}

.dark{
  color: var(--dark);
}
h1.alt, h2.alt{
  color: var(--dark-grey);
}
h3.alt, h4.alt{
  color: var(--grey);
}

p.alt{
  color: var(--grey);
  font-weight: 400;
}

.ml6 {
  position: relative;

}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
}

.ml6 .word {
  display: inline-block;
  box-sizing: border-box;
  padding-top: 0.07rem;
  padding-right: 0.05rem;
  padding-bottom: 0.5rem;
  white-space: pre;
}


.word-wrapper{
  position: relative;
  display: inline-block;
  overflow: hidden;
}



.arrow-up{
  transform: rotate(90deg);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0;
  padding: 0;
  height: 2rem;
}
.arrow-up:hover{
  cursor: pointer;
}
.border-box{
  border: 1px solid var(--pink);
  padding-right: 1rem;
}

div.border-box{
  margin-top: 1rem;
  margin-left: 0rem;
  padding: 0.5rem;
  align-items: center;
  padding-right: 1rem;
}
div.border-box:hover{
  cursor: pointer;
  background-color: var(--pink);
}
div.border-box:hover > h3{
  color: var(--white);
}

div.border-box.white:hover{
  background-color: var(--white);
}
div.border-box.white:hover > h3{
  color: var(--pink);
}

iframe{
  z-index: 9999;
}

.circle{
  width: 1120px;
  height: 1120px;
  min-width: 1120px;
  min-height: 1120px;
  border: 1px solid var(--dark-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: circles 6s infinite;
  pointer-events:  none;
  z-index: -1;
}
.circle > .circle{
  width: 695px;
  height: 695px;
  min-width: 695px;
  min-height: 695px;
  animation-delay: 0.25s;

}
.circle > .circle > .circle{
  width: 353px;
  height: 353px;
  min-width: 353px;
  min-height: 353px;
  animation-delay: 0.5s;
}

.blinking-cursor::after{
  content: "_";
  animation: blink 1s step-end infinite;
}
@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes blink2 {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(-1);
  }
}
@keyframes circles{
  from,
  to {
    opacity: 0%;
  }
  50%{
    opacity: 100%;
  }
  75%{
    opacity: 100%;
  }
  100%{
    opacity: 0%;
  }
}
/* SCROLL BAR STYLING */

/* width */

/*
::-webkit-scrollbar {
  width: 1rem;
}
*/

/* Track */
/*
::-webkit-scrollbar-track {
  border-left: 1px solid var(--dark-grey);
}
*/

/* Handle */
/*
::-webkit-scrollbar-thumb {
  border: 1px solid var(--pink);

}
*/

/* Handle on hover */

/*
::-webkit-scrollbar-thumb:hover {
  background-color: var(--pink);
}
*/

.parallax-image{
  width: 100%;
  height: 95%;
  object-fit: cover;
  object-position: 100% center;
  transition: filter 0.5s linear;
  filter: brightness(0);
}
.parallax-container{
  clip-path: inset(5% 5% 5% 5%);
}

p.cycle{
  margin:0;
}
.cycle-container{
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 1.5rem;
  max-height: 1.5rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--grey);

}


/*Helpers*/
.right-align{
  margin-left: auto;
  margin-right: 0;
  right: 0px;

}
.left-align{
  position: relative;
  margin-right: auto;
  margin-left: 0;
  left: 0px;
}
.abs{
  position:absolute;
  z-index: 1;
}
.alt{
  color: var(--dark-grey);
}

.center{
  justify-content: center;
  align-items: center;
}

.right-align{
  display: inline-flex;
  margin-left: auto;
}

.flex{
  display: flex;
}

.inline-flex{
  display: inline-flex;
}
.col{
  flex-direction: column;
}
.row{
  flex-direction: row;
}


.hide{
  display: none;
}

.close{
  display: none;
}
.close:hover{
  background-color: var(--pink);
  cursor: pointer;
}
.close > h4{
  pointer-events: none;
}

a.link > p:hover{
  text-decoration: underline;
  color: var(--pink);
}
a:hover > h4{
  color: var(--pink);
}


/* General Containers */

.wrapper{
  display: flex;
  flex-direction: column;
  width: 100vw;

  max-width: 100vw;
  min-height: 100vh;
}

.container{
  box-sizing: border-box;
  padding: 0vw 12vw 0vw 12vw;
  width: 100%;
  height: 95vh;
  min-height: 95vh;
  display: flex;
}
.container.mini{
  height: 10vh;
  min-height: 10vh;
}
.max-container{
  position: absolute;
  width: 100vw;
  height: 95vh;
}

.stats-container > div{
    position: relative;
    box-sizing: border-box;
    width: 25vw;
    padding: 2rem;
}

.header-container{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100vw;
    height: 5rem;
    padding: 0rem 12vw 0rem 12vw;
    align-items: center;
    z-index: 9999;
    mix-blend-mode: difference;
}
#builder-headline{
  width: 50rem;
  text-align: center;
}

.mini-container{
  height: 40vh;
  min-height: 40vh;
  max-height: 40vh;
}
.content{
  padding-left: 8rem;

}
.underline{
  height: 1px;
  width: 0%;
  transition: width 0.25s ease-in-out 0s;
  background-color: var(--grey);
  z-index: 1;
}

.underline-full{
  width: 100%;
  height: 1px;
  background-color: var(--dark-grey);
  transform: translateY(-1px);
  z-index: 0;
}

.underline.alt{
  background-color: var(--grey);
}

.underline-full.alt{
  display: none;
}


/* Components */
.square-image{
  z-index: -1;
  object-fit: cover;
  height: 50%;
  width: 50%;

}
.square-image.left{
  left: -10%;
}
.square-image.right{
  right: -10%;
}
.half-image{
  object-fit: cover;
  height: 100%;
  width: 45rem;
}
.gradient{
  box-sizing: border-box;
  overflow: hidden;
  bottom: 20%;
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
}
.gradient-bottom{
  box-sizing: border-box;
  overflow: hidden;
  bottom: 20%;
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
.gradient-right{
  overflow: hidden;
  position: absolute;
  width: 35vw;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
}

.gradient-left{
  overflow: hidden;
  position: absolute;
  width: 35vw;
  height: 100%;
  pointer-events: none;
  right: 0px;
  top: 0px;
  background-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
}

.banner-gradient > div{
  width: 22%;

}
.banner-gradient{
    padding-left: 8rem;
}

/* Button */
.button{
  box-sizing: border-box;
  display: inline-flex;
  border: 1px solid;
  border-color: var(--light-grey);
  background-color: var(--white);
  pointer-events: auto;
  border-radius: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}

.button.alt:hover{
  background-color: #E2E2E2;
  border-color: var(--grey);
}
.button:hover{
  cursor: pointer;
  background-color: var(--white);
}


.button > h4{
  margin: 1rem 1.25rem 1rem 1.25rem;
  color: var(--grey);
  transition: color 0.25s ease-in;
}


.button:hover > img{
  filter: saturate(0%) brightness(500%);
}

.button.alt{
  border-color: var(--light-grey);
}
.button.alt > h4{
  color: var(--grey);
}





/* GRID */
.black{
  background-color: var(--dark);
}
.pink{
  transition-delay: 0.2s;
  background-color: var(--pink);
}

.grid-item{
  transition-delay: 0.25s;

  border: 1px solid;
  border-color: #3c0020;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;

}
.grid-item:hover{
  transition-delay: 0s;
  background-color: var(--pink);
}

/* FOOTER */
footer{
  box-sizing: border-box;
  display: flex;
  width: 100%;
  bottom: 0;
  background-color: var(--dark);
  height: 20vh;
  padding: 0rem 12vw 0rem 12vw;


}

footer > h2{
  margin-right: 2rem;
}

/* SIDEBAR */
.sidebar-container{

  background-color: var(--dark);
  display: inline-flex;
  position: fixed;
  height: 99%;
  border-right: var(--pink) solid 1px;
  padding: 3rem;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  z-index: 100;
}
.sidebar-container::-webkit-scrollbar{
  display: none;
}

.close-sidebar{
  color: var(--pink);
  display: inline-flex;
  padding: 0.5rem;
}
.close-sidebar:hover{
  cursor: pointer;
  color: var(--white);
}

.dropdown{
  display: flex;
  align-items: center;
  border: 1px solid var(--pink);
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  background-color: var(--dark);
  margin-top: 1rem;
}
.dropdown > .arrow-up{
  margin-left: auto;
}

li > h4{
  color: var(--pink);
}
.dropdown-item{
  display: inline-flex;
  margin-top: 1rem;
  margin-left: 1rem;
}

a.dropdown-item:hover > h4, a.dropdown-item:hover > p{
  color: var(--pink);
}

.dropdown-item.alt:hover > h4, .dropdown-item.alt:hover > p{
  color: var(--white);
}
.dropdown-item.alt > h4, .dropdown-item.alt > p{
  color: var(--pink);
}
.dropdown-item > p{
  width: 13rem;
  max-width: 15rem;
}

#pro-drop:hover{
  cursor: pointer;
  background-color: var(--pink);
}
#pro-drop:hover > h4, #pro-drop:hover > .arrow-up{
  color: var(--white);
}

#hobby-drop:hover{
  cursor: pointer;
  background-color: var(--white);
}
#hobby-drop:hover > h4, #hobby-drop:hover > .arrow-up, #pro-menu > div > h4, #pro-menu > div > p{
  color: var(--pink);

}

#decentralized{
  color: var(--pink);
  font-size: 4rem;
}
#hero-desc{
  width: 11rem;
  margin-top: 1rem;
  margin-left: 4rem;
}
#hero-line{
  margin-top: 5rem;
  width: 40rem;
  max-width: 40rem;
  margin-left: 2rem;
  margin-right: 1rem;
}
#hero-tag{
  position: relative;
  width: 15rem;
  left: 40vw;
  top: 10rem;
}

.wires{
  height: 110%;
  max-height: 110%;
  z-index: -1;
}

.indicator{
  width: 0.25rem;
  height: 0.25rem;
  max-width: 0.25rem;
  max-height: 0.25rem;
  min-width: 0.25rem;
  min-height: 0.25rem;
  border: 0.2rem solid var(--pink);
  transform: rotate(45deg);
  margin-left: 1rem;
  margin-top: 0.2rem;
  display: none;
}

/* Milestones */
.milestone-container{
  border: 1px solid var(--pink);
  padding: 0rem 2rem 3rem 2rem;
  width: 35%;
}


.milestone-desc-container{
  border: 1px dashed var(--pink);
  border-top-style: none;
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
}

.sla-container{
  border: 1px dashed var(--pink);
  padding: 1rem;
  margin-top: 2rem;
  margin-right: 2rem;
  padding-bottom: 2rem;
  max-width: 50%;
}
.white{
  border-color: var(--grey);
}

.milestone-other{
  margin-left: 1rem;
  margin-top: 0.5rem;
  color: var(--grey);
}

.milestone-description{
  font-weight: 200;
  margin-top: 0.5rem;
}

.reward-cost > div{
  width: 50%;
}

.tasks-container{
  width: 40%;
}

.milestone-container > div:hover > .arrow-up{
  color: var(--white);
}

h1.white, h2.white, h3.white, h4.white{
  color: var(--white);
}

/* BUILDERS PAGE */
.builder-card-container{
  height: 6rem;
  max-height: 6rem;
  min-height: 6rem;
  width: 16rem;
  padding: 2rem 1rem 2rem 1rem;
  margin: 1rem;
  border: 1px solid var(--dark-grey);
  border-radius: 0.5rem;
}

.builder-icon{
  border-radius: 50%;
  width: 3rem;
  margin-right: 1rem;
}

.card-container{
  width: 60%;
  flex-wrap: wrap;
}

.sidebar-popout-container{
  display: none;
  height: 0px;
}
.sidebar-popout-content{
  display: none;
}
.sidebar-popout-content:hover{
  cursor: pointer;
}
.sidebar-popout-content > h4, .sidebar-popout-content > h3{
  pointer-events: none;
}

.hamburger-container{
  position: fixed;
  width: 2rem;
  height: 2rem;
  z-index: 101;
  margin-right: 2rem;
  margin-top: 2rem;
  padding: 1rem;

}

.menu-icon {
   display:flex;
   justify-content: center;
   align-items: center;
	 position: relative;
	 width: 22px;
	 height: 12px;
	 cursor: pointer;

   padding: 0.5rem;
}
 .menu-icon .menu-icon__cheeckbox {
	 display: block;
	 width: 100%;
	 height: 100%;
	 position: relative;
	 cursor: pointer;
	 z-index: 2;
	 -webkit-touch-callout: none;
	 position: absolute;
	 opacity: 0;
}
 .menu-icon div {
	 position: absolute;
	 top: 0;
	 right: 0;
	 left: 0;
	 bottom: 0;
	 width: 22px;
	 height: 12px;

}
 .menu-icon span {
	 position: absolute;
	 display: block;
	 width: 100%;
	 height: 3px;
	 background-color: var(--white);
	 border-radius: 1px;
	 transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
 .menu-icon span:first-of-type {
	 top: 0;
}
 .menu-icon span:last-of-type {
	 bottom: 0;
}
 .menu-icon.active span:first-of-type, .menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
	 transform: rotate(45deg);
	 top: 4.5px;
}
 .menu-icon.active span:last-of-type, .menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
	 transform: rotate(-45deg);
	 bottom: 4.5px;
}
 .menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
	 width: 22px;
}

.menu-icon:hover span:first-of-type {
  width: 26px;
}
.menu-icon:hover span:last-of-type {
  width: 12px;
}

.questions-container{
  box-sizing: border-box;
  padding: 0vw 12vw 0vw 12vw;
  width: 100%;
  height: 50vh;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}
.questions-container > .questions-header > div {
  flex-direction: column;
}
.questions-header{
  flex-direction: row;
}
.questions-header > div {
  margin-left: 2rem;
}

#element-banner{
  margin-top: 3rem;
}

.big-menu-container{
  box-sizing: border-box;
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0px;
  background-color: var(--white);
  z-index: 3;
  padding: 0vw 24vw 0vw 24vw;
  right: 0;
  transition: opacity 0.25s linear;
  transform: translateY(-100vh);
  transition: transform 0.25s ease-in-out;
  justify-content: center;
}

.big-menu-container.show{
  transform: translateY(0vh);
}
.big-menu-container.hide{
  transform: translateY(-100vh);
}

.menu-item > h2, .menu-item > h1{
  z-index: 2;
  text-shadow: 2px 0 var(--dark-grey), -2px 0 var(--dark-grey), 0 2px var(--dark-grey), 0 -2px var(--dark-grey),
               1px 1px var(--dark-grey), -1px -1px var(--dark-grey), 1px -1px var(--dark-grey), -1px 1px var(--dark-grey);

}
.menu-item > h1{
  margin-top: 2rem;
}
.menu-item{
  margin-right: 2rem;
}
.menu-item > h2{
  pointer-events: none;
}
.menu-item:hover{
  cursor:pointer;
}
.menu-item:hover > .underline{
  width: 100%;
}


.menu-item.alt > h2, .menu-item.alt > h1{
  z-index: 2;
  color: var(--dark-grey);
  text-shadow: 2px 0 var(--white), -2px 0 var(--white), 0 2px var(--white), 0 -2px var(--white),
               1px 1px var(--white), -1px -1px var(--white), 1px -1px var(--white), -1px 1px var(--white);
}
.builder-icon{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: var(--white);
  width: 3rem;
  height: 3rem;
}

.home-icon{
  transition: opacity 0.25s linear;
  z-index: 9999;
}
.home-icon:hover{
  opacity: 0.5;
}

.icon{
  display: inline-flex;
  margin: 3rem;
  height: 3rem;
  align-items: center;
  opacity: 0;
}
.service > img{
  width: 3rem;
  opacity: 0.33;
}
.icon-large{
  width: 5rem;
  margin: 0.5rem;
}

/* Carousel */



#image-track {
  display: flex;
  gap: 4vmin;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
   /* -- Prevent image highlighting -- */
}
#image-track{
  cursor: grab;
}

#image-track > .image-container > .image, .image {
  width: 32vmin;
  height: 32vmin;
  object-fit: contain;
  object-position: 50% center;
  border: 1px solid var(--grey);
  border-radius: 0.5rem;
  background-color: var(--white);
}


#element-banner{
  width: 100%;
  height: auto;
  bottom: 0px;
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  box-shadow: 0 3px 3px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 5px 5px rgba(0,0,0,0.12),
              0 10px 10px rgba(0,0,0,0.12),
              0 20px 20px rgba(0,0,0,0.12);
}
#element-banner > img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .container{
    padding: 0vw;
  }
  html{
    font-size:12px;
  }

  /* SIDEBAR */
  .sidebar-container.hide {
    transform: translate(-110%);
  }
  .sidebar-container.show{
    transform: translate(0%);
  }

  .sidebar-container{
    width: 50%;
    transition: transform 0.25s;
    transition-timing-function: ease-in;
  }

  .milestone-container{
    width: 50%;
  }
  .sidebar-popout-container.show{
    display:none;
  }
  .sidebar-popout-container{
    display: flex;


  }
  .sidebar-popout-content{
    display: flex;
    position: fixed;
    margin: auto;
    z-index: 100;
    width: 5rem;
    height: 2rem;
    background-color: var(--dark);
    border: 1px solid var(--pink);
    border-left-style: none;

  }
  .sidebar-popout-content > h1{
    pointer-events: none;
  }
  .sidebar-popout-content.show > h1{
    transform: rotate(180deg);
  }
  .sidebar-popout-content.hide > h1{
    transform: rotate(0deg);
  }
  .sidebar-popout-content:hover{
    transform: translateX(0rem);
    background-color: var(--pink);
  }
  .close{
    display: flex;
    border: 1px solid var(--dark-grey);
    padding: 0.25rem;
    margin-bottom: 1rem;
  }

  .banner-gradient{
    padding-left: 8rem;
  }
  .banner-gradient > div{
    width: 100%;
  }


}

@media only screen and (max-width: 900px) {
  h1{
    font-size: 3.5rem;
  }
  html{
    font-size:12px;
  }
  #builder-headline{
    width: 41rem;
    text-align: center;
  }
  .big-menu-container{
    padding: 0vw 6vw 0vw 0vw;
    flex-direction: row;
  }
  .big-menu-container > div{
    flex-direction: column;
  }
  
  .milestone-container{
    width: 80%;
  }
  .half-image{
    display: none;
  }

  .hamburger-container:hover{
    cursor: pointer;
  }
  .big-menu-container > div > div{
    margin-top: 1rem;
    padding: 0.25rem;
  }


  .stats-container{
      flex-direction: column;
      position: relative;
      box-sizing: border-box;
  }

  .stats-container > div{
    flex-direction: column;
    width: 80vw;
  }
  .container{
    padding: 0;
  }

  .banner-gradient{
    padding-left: 8rem;
  }
  .banner-gradient > div{
    width: 100%;
  }

  #decentralized{
    color: var(--pink);
    font-size: 3rem;
  }
  #hero-desc{
    width: 70vw;
    margin-top: 1rem;
    margin-left: 0rem;
  }
  #hero-line{
    width: 70vw;
  }
  .square-image{
    width: 150%;
  }
  .square-image.left{
    left: -110%;
  }
  .square-image.right{
    right: -110%;
  }

  .client > img{
    height: 3rem;
  }
  .client{
    margin: 1rem;
  }
  /* width */
  ::-webkit-scrollbar {
    display: none;
  }

  .questions-container{
    flex-direction: column;
  }
  .questions-header{
    flex-direction: column;
  }

  .footer-icons{
    display: none;
  }
}


/* tempy */
.box {
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  background-color: plum;
  border: 1px solid rgb(0, 0, 0);
}

.items {
  position: relative;
  width: 100vw;
  left: 0;
  gap: 4vmin;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: grab;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  grid-auto-flow: column;
}

.items {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.items::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.track-container{
  padding: 6% 0 0 6%;
}


.text-updater-node {
  display: flex;
  flex-direction: column;
  width: 264px;
  border: 1px solid var(--grey);
  padding: 5px;
  border-radius: 2px;
  background-color: var(--dark);
  padding: 2rem 1rem 2rem 1rem;
  min-height: 320px;
}

.text-updater-node > .node {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2.node.alt{
  color: var(--pink);
}
h2.node {
  font-weight: 700;
  font-size: 3rem;
}
h1.node {
  font-size: 5rem;
  font-weight: 700;
}
.collapsible{
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--grey);
  width: 296px;
  left: 0;
  margin-left: 0rem;
  padding: 0.25rem 0rem 0.25rem 0rem;
}
.collapsible, .task-collapse{
  transition: background-color 0.25s linear;
}
.collapsible:hover, .task-collapse:hover{
  background-color: var(--grey);
  cursor: pointer;
}

.node-desc {
  display: flex;
  line-height: 125%;
  font-family: 'Inter';
  font-weight: 300;
  color: var(--light-grey);
  font-size: 0.825rem;
}

.node-list > li{
  font-family: 'Inter';
  font-weight: 200;
  color: var(--light-grey);
  font-size: 0.85rem;
  list-style-type: none;
  margin-bottom: 0.5rem;
}
.reward, .cost{
  position:relative;

}

h2.hobbyist, h2.pro, h2.prereq{
  text-transform: uppercase;
}
h2.hobbyist{
  color: var(--grey);
}
h2.pro{
  color: var(--pink);
}
h2.prereq{
  color: var(--white);
}

path{
  stroke:red;
}

.node-description{
  width: 100%;
}

.node-block{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.node-subheader{
  font-size: 1rem;
}


.task-collapse{
  width: 100%;
  padding: 0.25rem 0rem 0.25rem 0rem;
  border: 1px solid var(--grey);
}

h4.collapse{
  font-weight: 500;
  text-transform: lowercase;
  margin: 0.25rem;
  margin-left: 0.5rem;
}
.collapsible-container{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}


